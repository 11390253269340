.sideNav{
    width: 58px;
    background: #1A2241;
    /* position:absolute; */
    float: left;
    height: 92.5vh;
    overflow-y: auto;
    transition:  all 1s;
/* left:-100%; */
}
 ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #E5E5E5; 
  }
.sideNav.active{
    left:0;
    width: 210px;
}
.logo1{
    
    margin: 0px 0px 0px 0px;
}
ul{
    padding: 0;
}
ul li{
    list-style-type: none;
    margin: 10px 0px;
}

ul li a{
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    display: block;
    padding: 10px 15px;
    border-radius: 6px;

}
ul li a:hover,
ul li a.active{
    background-color: blue;
}
header svg{
    font-size: 17px;
    margin: auto 0px;
}
.loginUserIcon svg{
    font-size: 27px;
    margin: auto 0px;
}
header{
    background-color:   #FAFBFC; 
    box-shadow: 0px 4px 12px #00000025;
    color: #2F2E41;
    padding: 9px 21px;
}
.subTitle{
    padding:0px 0px 0px 21px;
}

.loginUserIcon{
    /* background-color: #1A2241; */
    color: #ffffff;
    /* font-size: 16px; */
    height: 30px;
    width: 30px;
    align-items: center;
    text-align: center;
    /* padding: 2px; */
    border-radius: 50px;
}

.dpsHeadding{
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    padding: 0px 17px;
    margin: auto 0px;
}
.loginUser{
    padding: 0px 0px 0px 17px;
}
.menuItem{
    color:#ffffff;
}
.sidenavIcon{
    padding: 5px 0px 0px 0px;
   
}
.sidenavOpen{
display: block;
}
.sidenavTitle{
    display: revert;
}
.SidenavHide{
    display: none;
}
.navBarName{
    display:felx;
color:#C1C1C1;
justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    list-style: none;
    height: 45px;
    text-decoration: none;
    font-size: 16px;
}

.navBarName:hover{
    background:#252531;
    border-left:4px solid #00AAC1;
    cursor:pointer;
    Color:#ffffff;
}
.navBarNameActive{
     background:#252531;
        border-left:4px solid #00AAC1;
        cursor:pointer;
        Color:#ffffff !important;
}
.logoDiv{
   padding-left: 0px !important;
}
.logo{
    width:206px;
}
.col-forLogo{
    width:14%;}
   