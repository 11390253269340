* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

:root {
    /* --color: #044--gret660; */
    /* --primary: #1a75bc; */
    /* --three-boxes: rgba(3, 49, 67, .4); */
    /* --three-boxes: rgba(3, 49, 67, .8); */
    /* --three-boxes: #1a75bc; */


    --btn-orange: #FF6C0D;

    --white: #fff;
    --grey: #ccc;
    --body-bg: #0e1525;
    --box-bg: #0b111f;
    --head-color: #1D99FD;
    --first-box-border: #5B2DF8;
    --second-box-border: #01C3FF;
    --red-border: #E4446F;
    --table-border: rgba(139, 124, 124, .26);
    --input-fill: rgba(40, 150, 236, .24);
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: var(--body-bg);
}

.background {
    width: 100%;
    /* min-height: 100vh; */
    /* background: url('./images/bg.jpg') no-repeat center; */
    background-size: cover;
    background: var(--color);
    background: var(--body-bg);
}

.logo {
    margin: 1.5rem 0;
}

#logo {
    width: 200px;
}

.margin-right {
    margin-right: 0.8rem;
    margin-bottom: 0;
}

nav {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0.9rem 1rem;
    height: 70px;
    background-color: var(--box-bg);
}

nav.showing {
    display: flex;
}

nav.showing #logo {
    width: 150px;
}

nav .connect-wallet {
    margin-bottom: 0;
}

nav p {
    font-weight: 500;
    color: #fff;
    font-size: 1rem;
}

nav i {
    font-size: 1.2rem;
}

.side-link {
    display: flex;
    align-items: center;
    position: relative;
}

.open-drop:hover {
    color: #ccc;
}

.show-drop {
    display: block !important;
}

.dropdown {
    /* z-index: -1; */
    position: absolute;
    top: 50px;
    right: 0;
    background: #fff;
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 5px;
    width: 150px;
    display: none;
}

.dropdown ul li {
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.dropdown ul li:hover {
    filter: brightness(80%);
}

.dropdown ul li a i {
    color: #333;
    margin-right: 0.5rem;
}

.dropdown ul li a {
    display: block;
    color: #333;
}

.head-main-page {
    color: var(--head-color) !important;
}



ul {
    list-style: none;
    padding-left: 0;
}

a {
    text-decoration: none;
    display: inline-block;
    color: #e6e6e6;
}

i {
    color: #fff;
}

.aic {
    align-items: center;
}

.form-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

form {
    padding: 25px !important;
    border-radius: 5px;
    background-color: var(--box-bg);

}

.forget-text {
    max-width: 350px;
    color: #ccc;
}

.form-head {
    text-align: center;
}

.form-head h2 {
    text-align: left;
    font-weight: 500;
    color: rgb(192, 192, 192);
    margin-bottom: 2rem;
    color: #fff;
}

.form-field {
    position: relative;
    /* display: grid;
    grid-template-columns: 150px 1fr; */
    margin: 1rem 0;
}

.form-f {
    position: relative;
}

.form-f i {
    position: absolute;
    left: 0;
    margin-top: 1px;
    /* top: 8px; */
    padding: 7px;
    z-index: 2;
    color: #333;
    /* background: #ccc; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-right: 1px solid #ccc;
}

label {
    color: #ccc;
    font-size: .85rem;
}

.create-account {
    color: #555;
    text-align: center;
    padding: 1rem 0;
    border-bottom: 1px solid #ccc;
}

.half-field {
    /* display: flex;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 12px;
}

.half-field input {
    /* max-width: max; */
}

.create-account a {
    margin-left: 0.5rem;
}

.copyright {
    margin: 0.5rem 0;
}

.copyright p {
    font-size: .9rem;
    color: #555;
}

.form-field select,
.form-field input {
    width: 100%;
    height: 33px;
    padding: 3px 6px !important;
    border: 1px solid var(--box-bg);
    min-width: 290px;
    border-radius: 3px;
}
.create-user-form{
    max-width: 600px;
    margin: auto;
}
.create-user-form .form-field input,
.create-user-form .form-field select {
    min-width: 150px !important;
}

.login-form input {
    padding-left: 40px;
}

.signup-form input {
    padding-left: 10px;

}

#image {
    background: #fff;
}

textarea {
    width: 100%;
}

.icon i {
    color: #000;
}

.form-field label {
    display: block;
    font-size: 0.85rem;
    color: #ccc;
    text-align: initial;
    margin-bottom: 3px;
}


.form-field input:focus {
    transform: scaleY(1.02);
}

.form-field .verify-btn,
.form-field .confirm-otp {
    display: inline-block;
    position: absolute;
    right: 0;
    border: 1px solid var(--btn-orange);
    /* color: #0; */
    background-color: var(--body-bg);
    background-color: var(--btn-orange);
    color: #000;
    padding: 6px 5px;
    height: 33px;
    font-size: .8rem;
}

.submit-buttons {
    display: block;
    text-align: right;
    margin-top: 2rem;
}

.btn-submit.first-btn {
    color: #fff;
    background: transparent;
    border: 1px solid var(--btn-orange);
    margin-right: 0.5rem;
}
.btn-submit:disabled {
    pointer-events: none;
    opacity: .65;
}
.btn-submit {
    font-weight: 600;
    outline: none;
    border: none;
    padding: 3px 21px;
    border-radius: 3px;
    border: 1px solid var(--btn-orange);
    background-color: var(--btn-orange);
}

.mr-3 {
    margin-right: 0.6rem;
}


/* DASHBOARD */



.dashboard-container {
    display: grid;
    grid-template-columns: 200px 1fr;
}

.sidebar {}

.large-view-area {
    padding: 20px 25px;
}

.sidebar i {
    margin-right: 0.5rem;
}

.sidebar {
    border-right: 1px solid var(--box-bg);
}

.first-link {
    margin-top: 1rem !important;
}

.hide-on-big {
    display: none;
}

.sidebar ul li {
    background-color: var(--body-bg);
    padding: 10px 5px;
    text-align: initial;
    padding-left: 20px;
    margin: 0.3rem 0;
}

.sidebar ul li.active {
    background-color: var(--box-bg);

}

.sidebar ul li:hover {
    background-color: var(--box-bg);
    text-decoration: none;
}

.sidebar ul li div {
    display: block;
    color: #fff;
    background: none;
    text-decoration: none;
}

.head-section {
    color: #ccc;
    margin: 1rem 0;
}

.heading-page {
    color: #ccc;
    font-weight: 400;
    text-align: initial;
    margin: 1rem 0;

}

.home-dash {
    background: var(--box-bg);
    padding: 10px;
    margin: 1rem 0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.home-dash a {
    font-size: .9rem;
}

.create-user-btn {
    background-color: var(--btn-orange);
    padding: 0.1rem 0.5rem;
    font-size: .9rem;
    border: none;
    border-radius: 5px;
    color: #fff !important;
}

.create-user-btn:hover {
    filter: brightness(90%);
}

.white {
    color: #ccc;
}

.dull {
    color: rgb(139, 139, 139);
}

.dash-boxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: 20px;
    margin-top: 1rem;
}

.dash-box {
    padding: 15px;
    border-radius: 10px;
    background: var(--box-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--btn-orange);
}

.dash-box div .large {
    font-size: 2.5rem;
    margin-bottom: 0;
    color: #fff;
}

.dash-box div .small {
    color: #ccc;
}

.dash-box i {
    font-size: 3.5rem;
    color: #ff6e0d75;
}

/*  USER TABLE */
.user-table {
    background: var(--box-bg);
    /* background: rgba(3, 49, 67, .5); */
    margin: 0 0;
    overflow-x: auto;
    padding: 10px;
}

.br-0 {
    border-radius: 0;
    border: none;
    padding: 5px 0;
    background: none;
    margin-bottom: 1.3rem;
}

table {
    font-family: sans-serif;
    border-collapse: collapse;
    width: 100%;
}

thead tr {
    background-color: var(--box-bg) !important;
    color: rgb(182, 182, 182);
}

tbody tr {
    background-color: var(--box-bg) !important;
    color: rgb(151, 149, 149);
}


tr th {
    font-weight: 600;
    padding-top: 14px;
    padding-bottom: 14px;
}

/* tbody tr {
    border-bottom: 1px solid /#03384d;
} */

tr td {
    font-weight: 400;
}

td,
th {
    /* border: 1px solid #043f56; */
    /* border-bottom: 0.5px solid #13526b; */
    text-align: left;
    padding: 6px;
    font-size: .85rem;
    /* color: #ddd; */
}

tr:nth-child(even) {
    background-color: var(--body-bg);
    /* background-color: rgba(3, 56, 77, .2); */
    /* color: #ccc; */
}

.ph-number {
    font-size: .85rem;
}

.edit-btn,
.delete-btn {
    background: var(--btn-orange);
    font-size: .9rem;
    border-radius: 3px;
    padding: 0 3px;
    margin: 3px;
    color: #fff;
}

.dataTables_length>label {
    display: none;
}

.dataTables_filter>label {
    margin-bottom: 0.5rem;
    color: rgb(151, 151, 151);

}

.right-field {
    text-align: right;
}


.profile-container {
    width: 100%;
    display: grid;
    place-content: center;
    /* max-width: 500px; */
    padding-top: 150px;
}

.profile-container .profile-pic {
    text-align: center;
    border-radius: 50%;
}

#user-pic {
    border-radius: 50%;
    margin-top: -140px;
}

.profile-container .form-field p {
    text-align: right;
    color: rgb(161, 161, 161);
}






.bar-btn {
    display: none;
}















@media (max-width:855px) {
    .form-stake {
        width: 100%;

        margin: 1rem auto;
    }

    .connect-other-wallet {
        width: 100%;
    }

    .wallet-address-active {
        font-size: 0.85rem;
    }

    .d-nonee-big {
        display: block;
    }

    .d-none-sm {
        display: none !important;
    }

    .three-boxes {
        margin-top: 1rem;
    }

    .half-w>* {
        width: 49%;
    }

    input[type='submit'] {
        width: 49% !important;

    }

    .cwte {
        display: flex !important;
        justify-content: flex-end !important;
        /* align-items: flex-end; */
        /* background: #2c718b; */
    }

    .wallet-head {
        font-size: 0.9rem;
    }
}

.show-later {
    display: none;
}

.show {
    display: block !important;
}

/* MArketplace Collection */
.marketplace-boxes {
    padding: 1rem 1rem;
    max-width: 100%;
    margin: 2rem auto;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: 20px;
}

.market-box {
    display: block;
    border-radius: 20px;
    background-color: var(--box-bg);
    /* max-width: 330px; */
    border: 1px solid #555;
    position: relative !important;
}

a {
    display: inline-block;
}

.red {
    /* position: absolute; */
    margin-top: 0.5rem;
    top: 0;
    left: 0;
    /* background: red; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
}

.center {
    text-align: center;
}

.market-box:hover {
    /* transform: scaleY(1.02); */
    box-shadow: 1px 2px 2px var(--body-bg);
}

.market-box .image {
    width: 100%;
}

#image-product {
    width: 100%;
    height: 250px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #ccc;
    width: 100%;


}

.market-box>div {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.market-box div h5 {
    font-size: 0.9rem;
    color: #ccc;
}

/* .market-box div:last-of-type {
    background: red;
} */

.market-box div {
    padding: 10px;
}

.market-box div p {
    color: rgb(163, 163, 163);
    font-size: .85rem;
    margin: 0;
    padding: 0;
    margin-top: -1.5rem;
}


/* Charts */
.charts-four {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    grid-gap: 20px;
}

.chart {
    background-color: var(--box-bg);
    padding: 10px;
    border-radius: 10px;
}

.chart h4 {
    color: #ccc;
}

tspan {
    fill: #ccc !important;
}

/* Location */
.gm-style .gm-style-iw-c {
    border-radius: 50%;
    /* min-width: 180px !important; */
}

.gm-style img {
    margin-right: 1rem;
    /* max-width: none; */
}

.bottom-margin {
    /* text-align: center; */
    margin-bottom: 0rem;
    font-weight: 900;
    font-size: 1.8rem;
    text-align: center;
}

.gm-ui-hover-effect {
    opacity: .6;
    display: none !important;
}
.ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border: none;
    outline: none;
}
.ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    /* font-feature-settings: 'tnum', "tnum"; */
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
}