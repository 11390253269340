.ant-table-thead > tr > th {
    position: relative;
    color: inherit;
    font-weight: 700;
    background: none !important;
    text-align: left;
    background: #fafafa;
    border-bottom: 2px solid #f0f0f0;
    transition: background 0.3s ease;
}
.ant-table-filter-trigger:hover {
    color: #ffffff !important;
    font-size: 18px !important;
    background: rgba(0, 0, 0, 0.04);
}
tbody>tr:hover {
    background-color: #000000 !important;
    
}
.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #000000 !important;
}
.ant-btn-primary {
    color: #fff;
    border-color: var(--btn-orange);
    background: var(--btn-orange);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: var(--btn-orange) ;
    border-color:  var(--btn-orange);
    background: #fff;
}
.ant-btn:hover, .ant-btn:focus {
    color:  var(--btn-orange);
    border-color:  var(--btn-orange);
    background: #fff;
}
.ant-empty-normal {
    margin: 32px 0;
    color: rgb(251 246 246 / 50%);
}
.ant-input:hover {
    border-color:  var(--btn-orange);
    border-right-width: 1px;
}
.ant-input:focus, .ant-input-focused {
    border-color:  var(--btn-orange);
    box-shadow: 0 0 0 2px  #ff6c0d33;
    border-right-width: 1px;
    outline: 0;
}
.ant-spin-container>ul li a:hover, .ant-spin-container>ul li a.active {
    background-color: var(--btn-orange);
    color: #ffffff;
}
.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border: 2px solid;
    border-color: var(--btn-orange);
    box-shadow: 0px 0px 10px var(--btn-orange);
    /* color: var(--btn-orange); */
}
ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    display: block;
    padding: 0px 15px;
    border-radius: 0px;
}
.ant-pagination-item:hover {
    border-color:  var(--btn-orange);
    transition: all 0.3s;
}
.ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    position: relative;
    padding: 6px;
    overflow-wrap: break-word;
}