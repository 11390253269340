.form-control {
    display: flex !important;
    padding: 0px 3px 0px 0px !important;
    height: 34px !important;
}
.passErrorMsg{
    color: #ff0000;
    width: min-content;
    width: 315px;
}
@media (max-width:450px) {
   
    .passErrorMsg {
        color: #ff4d4f;
        width: min-content;
        width: 245px;
        text-align: center;
    }
}